<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="primary white--text headline">
        {{ $t("rules") | capitalize }}
      </v-card-title>

      <v-row class="pa-4" justify="space-between">
        <v-col cols="12" md="5">
          <Treeview />
        </v-col>
        <v-divider vertical v-if="$vuetify.breakpoint.mdAndUp"></v-divider>
        <v-col class="d-flex ">
          <Detail />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  components: {
    Treeview: () => import("@/components/Rules/RulesTreeview"),
    Detail: () => import("@/components/Rules/RuleDetail"),
  },
  created() {
    this.$store.dispatch("rules/loadRules");
  },
};
</script>
